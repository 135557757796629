

































import { defineComponent, onMounted } from "@vue/composition-api";
import { userModule } from "@/store/modules/user";
import Moment from "@/services/moment";
import useAsyncData from "@/hooks/useAsyncData";
import { getAllNewsMessagesForUser } from "@/api/newsService";
import { uiModule } from "@/store/modules/ui";

export default defineComponent({
  name: "News",
  setup() {
    onMounted(async() => {
      const now = Moment().unix();
      await userModule.actions.updateUserObject({ lastReadNewsMessages: now });
      await uiModule.actions.fetchUnreadNewsMessageCount();
      // for refreshing in old app
      const event = new Event("refreshUnreadNewsMessageCount");
      document.dispatchEvent(event);
    });
    const {
      isLoading,
      data: newsMessages,
    } = useAsyncData(() =>
      getAllNewsMessagesForUser(userModule.state.user.seller),
    );
    const readableDate = (timestamp: number) => {
      return Moment.unix(timestamp).format("LL");
    };
    return {
      readableDate,
      isLoading,
      newsMessages,
    };
  },
});
